<template>
    <v-app>
        <v-main>
            <component :is="$route.meta.layout || 'div'">
                <router-view></router-view>
            </component>
        </v-main>

        <DialogComp v-model="is_voice_play" title="음성출력 중" width="300">
            <template v-slot:body>
                <div class="voice_play">음성출력 중</div>
            </template>
        </DialogComp>

        <DialogComp v-model="v_dialog.advanced" title="고급 검색" fullscreen class="no_actions no_bottom"
            contentType="SEARCH_EXPERT">
            <template v-slot:body>
                <AdvancedSearch @close_adv_option="close_adv_option" />
            </template>
        </DialogComp>

        <DialogComp v-model="v_dialog.drug" title="의약품 상세" fullscreen class="no_actions drug" contentType="DRUG">

            <template v-slot:body>
                <DrugComp :item_seq="item_seq" :q1="q1" :q2="q2" @close="v_dialog.drug = false" />
            </template>
        </DialogComp>

        <DialogComp v-model="v_dialog.qr" title="QR · 바코드 검색" fullscreen class="no_actions no_padding"
            contentType="SEARCH_QRCODE">

            <template v-slot:body>
                <CameraComp @open_drug="open_drug" @prescription_qr="prescription_qr" @expert_share="expert_share"
                    @close="v_dialog.qr = false" />
            </template>
        </DialogComp>

        <DialogComp v-model="v_dialog.shape" title="낱알검색" fullscreen class="no_actions no_bottom"
            contentType="SEARCH_SHAPE">

            <template v-slot:body>
                <PillSearch @close_pill_option="close_pill_option" />
            </template>
        </DialogComp>

        <DialogComp v-model="v_dialog.voice" title="음성 검색" fullscreen class="no_actions no_bottom"
            contentType="SEARCH_VOICE">

            <template v-slot:body>
                <SpeechRecon ref="SpeechRecon" v-if="!setting.use_api" @close="v_dialog.voice = false"
                    @search="search" />
                <SpeechReconAPI ref="SpeechReconAPI" v-if="setting.use_api" @close="v_dialog.voice = false"
                    @search="search" />
            </template>
        </DialogComp>

        <DialogComp v-model="v_dialog.image" title="이미지 상세" fullscreen class="popup_image no_padding no_actions">

            <template v-slot:body>
                <div class="canvas" :class="{ 'zoom_out': !popup.image.is_zoom }">
                    <img :src="image_url" />
                </div>
                <div class="btn_wrap" v-if="popup.image.has_zoom">
                    <v-btn icon dark v-if="!popup.image.is_zoom" @click="popup.image.is_zoom = true">
                        <v-icon>mdi-magnify-plus-outline</v-icon>
                    </v-btn>
                    <v-btn icon dark v-if="popup.image.is_zoom" @click="popup.image.is_zoom = false">
                        <v-icon>mdi-magnify-minus-outline</v-icon>
                    </v-btn>
                </div>
            </template>
        </DialogComp>

        <DialogComp v-model="v_dialog.saft_letter" title="안전성서한" :fullscreen="true" class="no_actions">
            <template v-slot:body>
                <SafetyLetter :no="popup.saft_lett_no" />
            </template>
        </DialogComp>

        <DialogComp v-model="v_dialog.setting" title="설정" class="no_actions" :size="setting.dialog.mini">
            <template v-slot:body>
                <SettingView />
            </template>
        </DialogComp>

        <DialogComp v-model="v_dialog.share_my_bio" title="내 약력 전송" class="no_actions" :size="setting.dialog.mini"
            contentType="SHARE_MY_BIO">
            <template v-slot:body>
                <QRCodeComp @close_share_my_bio="close_share_my_bio"/>
            </template>
        </DialogComp>


        <DialogComp v-model="v_dialog.prescription_preview" title="처방이력 조회" class="no_actions"
            :size="setting.dialog.mini">

            <template v-slot:body>
                <PrescriptionPreview :qr_message="temp.txt" @prescription_detail="prescription_detail"
                    @close="v_dialog.prescription_preview = false" />
            </template>
        </DialogComp>

        <DialogComp v-model="v_dialog.prescription_detail" title="처방이력 조회" class="no_actions"
            :size="setting.dialog.mini">

            <template v-slot:body>
                <PrescriptionDetail :uuid="temp.uuid" />
            </template>
        </DialogComp>

        <DialogComp v-model="v_dialog.push" title="푸시 메세지 수신 안내" class="no_actions" :size="setting.dialog.mini">

            <template v-slot:body>
                <GuideSpan>푸시메세지 수신 허용 방법 소개 (예)</GuideSpan>
                <div class="guide">
                    <span>삼성인터넷</span>
                    <pre>

1. 삼성인터넷에서 www.edrugsafe.org에 접속한다.
2. 브라우저하단의 메뉴, 설정을 선택한다.
3. 사이트 및 다운로드를 선택한다.
4. 알림을 선택한다.
5. 상단의 <v-icon>mdi-dots-vertical</v-icon>을 선택하고 허용 또는 차단된 사이트를 선택한다.
6. "사용 중"으로 설정하고, 아래 목록중 www.edrugsafe.org 도 허용한다.
                    </pre>
                </div>
                <div class="guide">
                    <span>아이폰</span>
                    <pre>
아이폰에서 알림 허용하기 위해서는 홈 아이콘을 삭제후 다시 만들어야 합니다.

1. 설정으로 들어간다.
2. 앱 리스트 중 '안전e약'을 선택한다.
3. 안전E약 접근 허용, 알림을 선택한다.
4. 알림허용의 스위치를 ON한다.
                    </pre>
                </div>


                <ButtonComp @click="push_set" v-if="token == null">푸시 수신 요청</ButtonComp>
            </template>
        </DialogComp>

        <DialogComp v-model="v_dialog.notification" title="알림이력" class="no_actions" :size="setting.dialog.mini"
            contentType="NOTIFICATION">

            <template v-slot:body>
                <ButtonComp @click="notification_read_all">전체 읽음</ButtonComp>
                <div class="notifications">
                    <div class="notification" v-for="item in notification.list" :key="item.uuid"
                        :class="{ 'new': item.status == 1 }" @click="notification_open(item)">
                        <span>
                            {{ this.$filters.formatDate(item.send_time) }}
                        </span>
                        <span>{{ item.text }}</span>
                    </div>
                </div>
            </template>
        </DialogComp>

        <DialogComp v-model="v_dialog.help_advanced_search" title="고급검색 가이드" class="no_actions"
            :size="setting.dialog.mini">

            <template v-slot:body>
                <div class="help_advanced_search">고급검색에서는 다양한 조건의 검색 기능을 제공합니다.<br>
                    각 필드별 검색창에서 다음과 같은 조건으로 검색할 수 있습니다.<br>
                    <br>
                    <p>1. 띄어쓰기 포함 정확히 일치하는 용어를 검색하고 싶은 경우: "" 안에 원하는 검색어를 입력해주세요 (예: "key word" )</p>
                    <p>2. 2개 이상의 검색어를 모두 포함하는 제품을 검색하고 싶은 경우(AND 조건): + 기호를 입력해주세요 (예: 고혈압 +당뇨)</p>
                    <p>3. 2개 이상의 검색어 중 적어도 1개 이상을 포함하는 제품을 검색하고 싶은 경우(OR 조건): , 기호 또는 공백을 입력해주세요 (예: 고혈압 당뇨 // 고혈압, 당뇨)
                    </p>
                    <p>4. 특정 검색어를 포함하지 않는 제품을 검색하고 싶은 경우(NOT 조건): - 기호를 입력해주세요 (예: -당뇨)</p>
                    <p>5. 특정집단에서의 주의사항 여부 체크박스 중복 선택 시 적어도 1개 이상을 만족하는 제품이 검색됩니다 (OR 조건)</p>
                </div>
            </template>
        </DialogComp>


        <DialogComp2 v-model="v_dialog.youtube" class="youtube">
            <template v-slot:body>
                <iframe class="youtube" :src="temp.youtube_link" frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </template>
        </DialogComp2>

        <DialogComp v-model="v_dialog.qr_view" title="QR코드 조회" class="no_actions" :size="setting.dialog.mini">

            <template v-slot:body>
                <pre class="block">{{ temp.txt }}</pre>
                <span>약정원 QR코드 테스트를 위한 화면입니다. 개발 완료 후 기능 삭제됩니다.</span>
                <ButtonComp @click="prescription_preview">처방이력 조회</ButtonComp>
            </template>
        </DialogComp>
    </v-app>
</template>

<script>
import AdvancedSearch from '@/components/search/AdvancedSearch.vue'
import ButtonComp from '@/components/ButtonComp.vue'
import CameraComp from '@/components/CameraComp.vue'
import DialogComp from '@/components/DialogComp.vue'
import DialogComp2 from '@/components/DialogComp2.vue'
import DrugComp from '@/components/DrugComp.vue'
import GuideSpan from '@/components/GuideSpan.vue'
import PillSearch from '@/components/search/PillSearch.vue'

import QRCodeComp from '@/components/QRCodeComp.vue'
import PrescriptionDetail from '@/components/my/drug/PrescriptionDetail.vue'
import PrescriptionPreview from '@/components/my/drug/PrescriptionPreview.vue'
import SafetyLetter from '@/views/notice/SafetyLetterView.vue'
import SettingView from '@/views/SettingView.vue'
import SpeechRecon from '@/components/SpeechRecon.vue'
import SpeechReconAPI from '@/components/SpeechReconAPI.vue'

import analytics from "@/assets/js/analytics";
import ng from '@/assets/js/ng'
import storage from '@/assets/js/storage';

//import { useLocale } from 'vuetify'

const packageInfo = require('../package.json');

export default {
    name: 'App',
    components: {
        AdvancedSearch,
        ButtonComp,
        CameraComp,
        DialogComp,
        DialogComp2,
        DrugComp,
        GuideSpan,
        PillSearch,
        QRCodeComp,
        PrescriptionDetail,
        PrescriptionPreview,
        SafetyLetter,
        SettingView,
        SpeechRecon,
        SpeechReconAPI
    },
    data: () => ({
        synth: null,
        is_voice_play: false,
        item_seq: '',
        q1: '',
        q2: '',
        is_apple: false,
        is_show_menu: false,
        image_url: '',
        v_dialog: {
            advanced: false,
            drug: false,
            help_advanced_search: false,
            image: false,
            qr: false,
            qr_view: false,
            notification: false,
            prescription_preview: false,
            prescription_detail: false,
            push: false,
            saft_letter: false,
            setting: false,
            shape: false,
            share_my_bio: false,
            youtube: false,
            voice: false,

        },
        popup: {
            image: {
                has_zoom: false,
                is_zoom: true
            },
            saft_lett_no: 0
        },
        popup_id: {

        },
        time: {
            user: 0
        },
        setting: {
            font_size: 1,
            width: 0,
            dialog: {
                mini: {
                    fullscreen: false,
                    min_width: 350,
                    max_width: 600
                }
            },
            version: '',
            use_api: false,
            use_final: false,
            use_grammar: false,
            grammar: null
        },
        temp: {
            txt: ``,
            youtube_link: '',
        },
        token: null,
        update_key: 0,
        user: {},
        has_notification: false,
        notification: {
            has_new: false,
            list: []
        },
        menu: false
    }),
    created() {
        this.setting.width = document.body.clientWidth;
        if (this.setting.width <= 430) this.setting.dialog.mini.fullscreen = true;
    },
    mounted() {
        this.setting.version = packageInfo.version;

        //this.$alert1 = this.$refs.alert.alert;
        //this.$confirm1 = this.$refs.alert.confirm;
        //this.$choose = this.$refs.alert.choose;

        //const { current } = useLocale()
        this.handleResize();
        //window.addEventListener('resize', this.handleResize);
        window.visualViewport.addEventListener("resize", this.handleResize);

        this.setting.font_size = storage.fontSize();
        this.setting.use_api = storage.useAPI(); //this.is_apple;
        this.setting.use_final = storage.useFinal(); //this.is_apple;
        this.setting.use_grammar = storage.useGrammar();

        this.synth = window.speechSynthesis;

        var userAgent = navigator.userAgent;
        this.is_apple = /iPhone|iPad|iPod|macintosh/i.test(userAgent)
        //this.is_apple = true;

        document.addEventListener("visibilitychange", () => {
            if (document.hidden) {
                this.on_hide();
            } else {
                this.on_show();
            }
        });

        this.load_user();

        //modal이 열려있을 경우 body scroll 정지
        /*
        if (this.is_apple == false) {
            window.onscroll = function () {
                if (ng.modal.is_open || ng.is_menu_open)
                    window.scrollTo(ng.modal.scrollLeft, ng.modal.scrollTop);
            };
        }*/
       // modal 오픈시 스크롤 위치 사용하기 위해 저장
       /*
        window.addEventListener('scroll', () => {
            document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
        });*/


        //var vm = this; //

        window.addEventListener('beforeunload', function () {
            analytics.log_s('S_HIDE', { path: this.$route.path, query: this.$route.query });
            /*
            var session_key =
                storage.session_key() == null ? "" : storage.session_key();

            var opt = {
                s_key: session_key,
                code: 'S_HIDE',
                path: vm.$route.path
            };

            fetch('/api/log/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(opt),
                keepalive: true
            });*/
        });

        window.addEventListener('unload', function () {
            analytics.log_s('S_HIDE', { path: this.$route.path, query: this.$route.query });
            /*
            var session_key =
                storage.session_key() == null ? "" : storage.session_key();

            var opt = {
                s_key: session_key,
                code: 'S_HIDE',
                path: vm.$route.path
            };

            fetch('/api/log/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(opt),
                keepalive: true
            });*/
        })

        window.addEventListener('load', function () {
            //window.history.pushState({}, '')
        })

        /*
        this.$api.etc.speech_grammar().then(response => {
            var arr = response.data.data.split('\n');
            this.$alert('grammar 처리')
            this.setting.grammar = '#JSGF V1.0; grammar colors; public <color> = ' + arr.join(' | ') + ' ;'
        }).catch(err => {
            console.error(err);
        })*/
    },
    methods: {
        send_out() {
            var session_key =
                storage.session_key() == null ? "" : storage.session_key();

            var opt = {
                s_key: session_key,
                code: 'S_HIDE',
                path: this.$route.path
            };

            fetch('/api/log/', {
                method: 'POST',
                body: new URLSearchParams(opt),
                keepalive: true
            });
        },
        close_pill_option() {
            if (this.popup_id['SEARCH_SHAPE'] != undefined) {
                this.popup_id['DRUG_SEARCH'] = {
                    code: 'SEARCH_SHAPE',
                    p_id: this.popup_id['SEARCH_SHAPE']
                };
            }
            this.v_dialog.shape = false;
        },
        close_adv_option() {
            if (this.popup_id['SEARCH_EXPERT'] != undefined) {
                this.popup_id['DRUG_SEARCH'] = {
                    code: 'SEARCH_EXPERT',
                    p_id: this.popup_id['SEARCH_EXPERT']
                };
            }
            this.v_dialog.advanced = false;
        },
        close_share_my_bio() {
            this.v_dialog.share_my_bio = false;
            this.$router.replace('/my/health');
        },
        expert_share(val) {
            localStorage.setItem('expert_share', val);
            this.v_dialog.qr = false;
            this.$router.push('/expert/share');
        },
        load_user() {
            this.$api.user.is_login().then(response => {
                if (response.data.user != undefined) {
                    this.set_user(response.data.user);
                    this.push_check();
                } else {
                    this.set_user(null);
                }
            }).catch(err => {
                console.error(err);
            })
        },
        logout2() {
            this.on_hide();
            this.$api.user.logout().then(() => {
                this.set_user(null);
                this.user = null;
                this.$router.replace('/');
                //document.location.href = '/';
            });
        },
        logout() {
            this.$confirm('로그아웃할까요?').then(rs => {
                if (rs.isConfirmed) {
                    this.logout2()
                }
            })
        },

        updateForce() {
            this.update_key++;
        },
        handleResize() {
            const doc = document.documentElement
            doc.style.setProperty('--app-height', `${window.innerHeight}px`);
            this.setting.width = document.body.clientWidth;
        },
        on_hide() {
            //storage.session_id.hide();
            analytics.log_s('S_HIDE', { path: this.$route.path, query: this.$route.query });
        },
        on_show() {
            //storage.session_id.show();
            analytics.log_s('S_SHOW', { path: this.$route.path, query: this.$route.query });
        },
        open_drug(val) {
            var p_id = this.popup_id['SEARCH_QRCODE'] != undefined ? this.popup_id['SEARCH_QRCODE'] : '';
            delete this.popup_id['SEARCH_QRCODE'];

            if (val.item_seq != undefined && val.item_seq != null && val.item_seq != '') {
                this.item_seq = val.item_seq + '';
                this.v_dialog.drug = true;
                if (p_id != '') analytics.log_p('P_SEARCH_QRCODE.ACT', p_id, val);
            } else if (val.edi_code != undefined && val.edi_code != null && val.edi_code != '') {
                this.item_seq = val.edi_code + '';
                this.q1 = val.q1;
                this.q2 = val.q2;
                this.v_dialog.drug = true;
                this.v_dialog.qr = false;
                if (p_id != '') analytics.log_p('P_SEARCH_QRCODE.ACT', p_id, val);
            } else {
                this.$alert('유효한 QR코드가 아닙니다.')
            }
        },
        pop_menu(val) {
            if (storage.is_login()) {
                this.v_dialog[val] = true;
            } else {
                this.$alert('로그인이 필요합니다.')
                this.$router.push('/user/login');
            }
        },
        prescription_detail(item) {
            this.temp.uuid = item.uuid;
            this.v_dialog.prescription_preview = false
            this.v_dialog.prescription_detail = true
        },
        prescription_qr(val) {
            this.temp.txt = val;
            this.v_dialog.qr = false;
            this.v_dialog.prescription_preview = true;
            //this.v_dialog.qr_view = true;

            try {
                ng.qr_decode(this.temp.txt);
            } catch (e) {
                this.$alert(e.message);
            }
        },
        prescription_preview() {
            this.v_dialog.qr_view = false;
            this.v_dialog.prescription_preview = true;
        },
        push_check() {
            if ((this.is_apple && window.navigator.standalone) || this.is_apple == false) {
                if (Notification != undefined) {
                    if (Notification.permission == 'denied') {
                        this.v_dialog.push = true;
                    } else if (Notification.permission == 'granted') {
                        this.push_set();
                    } else {
                        this.$alert('푸시 메세지 수신해주세요.').then(() => {
                            this.push_set();
                        })
                    }
                }
            }
        },
        push_set() {
            this.$setPush().then(token => {
                var data = {
                    token: token
                };
                this.$api.push.subscribe({}, {}, data).then(() => {
                    storage.token(token);
                    this.token = token;
                }).catch(err => {
                    console.error(err);
                });
            }).catch(err => {
                console.error(err);
                if (err.message.indexOf('The notification permission was not granted and blocked instead.') != -1) {
                    if (this.v_dialog.push == false) this.v_dialog.push = true;
                    else {
                        this.$alert('설정에서 알림을 허용으로 해주세요.')
                    }
                }
            })
        },
        search(val) {
            if (this.popup_id['SEARCH_VOICE'] != undefined) {
                this.popup_id['DRUG_SEARCH'] = {
                    code: 'SEARCH_VOICE',
                    p_id: this.popup_id['SEARCH_VOICE']
                };
            }

            var p_id = this.popup_id['SEARCH_VOICE'] != undefined ? this.popup_id['SEARCH_VOICE'] : '';
            delete this.popup_id['SEARCH_VOICE'];
            if (p_id != '') analytics.log_p('P_SEARCH_VOICE.ACT', p_id, { st: val });

            this.v_dialog.voice = false;
            this.$router.push({ path: "/drug/search", query: { mode: 'basic', st: val, from: 'voice' }, replace: true });
        },
        notification_open(item) {
            var url = '';
            if (item.action_url.startsWith("https://www.edrugsafe.org/#")) {
                url = item.action_url;
                url = url.replace("https://www.edrugsafe.org/#", "");

                if (url.startsWith("/safety_notices")) {
                    url = url.replace("/safety_notices", "/notice/safety_letter")
                }
                this.$router.push(url);
            } else {
                window.open(item.action_url)
            }

            this.v_dialog.notification = false;

            this.$api.my.notifications.get({ uuid: item.uuid }).then(() => {
                this.notification_set();
            })
        },
        notification_read_all() {
            this.$api.my.notifications.read_all().then(() => {
                this.notification_set();
            })
        },
        notification_read(item) {
            this.$api.my.notifications.get({ uuid: item.uuid }).then(() => {
                this.notification_set();
            })
        },
        notification_set() {
            this.$api.my.notifications.list().then(response => {
                var cnt = 0;
                response.data.data.forEach(n => {
                    if (n.status == 1) cnt++;
                });
                this.notification.list = response.data.data;
                if (cnt > 0) this.notification.has_new = true;
                else this.notification.has_new = false;
            })
        },
        safety_letter(no) {
            this.popup.saft_lett_no = no + "";
            this.v_dialog.saft_letter = true;
        },
        set_user(user) {
            this.user = user;
            if (user == null) {
                if (storage.token() != null) {
                    this.$api.push.unsubscribe({}, {}, { token: storage.token() });
                    this.$removePush();
                }
                storage.logout();
            } else {
                storage.user(user);
                this.set_keyword();
                this.notification_set();
                this.on_show();
            }
            this.time.user = this.time.user + 1;
        },
        set_keyword() {
            this.$api.my.info.bio_keyword().then(response => {
                storage.user_keyword(response.data.data)
            });
        },
        show_image(url) {
            this.v_dialog.image = true;

            var vm = this;

            const img = new Image();
            img.onload = function () {
                vm.image_url = url;
                if (this.width > document.body.clientWidth || this.height > document.body.clientHeight) {
                    vm.popup.image.has_zoom = true;
                    vm.popup.image.is_zoom = false;
                }
            }
            img.src = url;
        },
        speech(val) {
            var vm = this;
            if (!this.is_voice_play) {
                this.is_voice_play = true;

                const utterThis = new SpeechSynthesisUtterance(val);
                utterThis.onend = function () {
                    vm.is_voice_play = false;
                };

                utterThis.onerror = function (event) {
                    console.error("SpeechSynthesisUtterance.onerror", event);
                };

                var voices = this.synth.getVoices()
                for (let i = 0; i < voices.length; i++) {
                    if (voices[i].name.indexOf('한국') != -1 || voices[i].name.indexOf('korea') != -1 || voices[i].name.indexOf('Korea') != -1) {
                        utterThis.voice = voices[i];
                        i = voices.length
                    }
                }

                utterThis.pitch = 1;//pitch.value;
                utterThis.rate = 1;//rate.value;
                this.synth.speak(utterThis);
            }
        },
        youtube(link) {
            var url = 'https://www.youtube.com/embed/' + link.replace('https://www.youtube.com/watch?v=', '')
            this.temp.youtube_link = url;
            this.v_dialog.youtube = true;
        },
        go(path) {
            console.log('go',path);
            this.$router.push(path);
        }
    },
    unmounted() {
    },
    watch: {
        is_voice_play(val) {
            if (!val) {
                this.synth.cancel();
            }
        },
        'v_dialog.drug'(val) {
            if (!val) {
                this.item_seq = '';
                this.q1 = '';
                this.q2 = '';
            }
        },
        'v_dialog.voice'(val) {
            if (!val) {
                if (this.$refs.SpeechRecon != undefined) this.$refs.SpeechRecon.stop();
                if (this.$refs.SpeechReconAPI != undefined) this.$refs.SpeechReconAPI.stop();
            }
        },
        'v_dialog.notification'(val) {
            if (val) {
                this.notification_set();
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

:root {
    color-scheme: light only;
    --app-height: 100%;
}

.popup_image img {
    display: block;
    width: fit-content;
    height: fit-content;
    margin: auto;
}

.voice_play {
    @include center;
    margin-top: 20px;
}

.canvas {
    display: flex;
    height: 100%;
}

.canvas.zoom_out {
    justify-content: center;
}

.canvas.zoom_out img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    object-fit: cover;
}

.popup_image .btn_wrap {
    position: fixed;
    bottom: 20px;
    right: 20px;
}

.modal_alert,
.modal_confirm {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;

    .button {
        margin-top: 20px;
    }
}

.modal_confirm {

    .button {
        display: flex;
    }

    .ButtonComp {
        width: calc(50% - 10px);
        margin: 0px 5px;
    }
}


.notifications {

    margin-top: 20px;

    .notification {
        margin-bottom: 10px;
        padding: 5px;
        border: 1px solid $b-g-3;
        border-radius: 5px;
        color: $b-g-4;

        span {
            display: block;
        }

        span:first-child {
            font-size: 14px;
        }
    }

    .notification.new {
        color: $b-g-7;
        border: 2px solid $b-g-3;
    }
}

.guide {
    span {
        display: block;
    }
}

div.help_advanced_search {

    p {
        margin-bottom: 10px;
    }
}

iframe.youtube {
    width: 100%;
    height: calc(100vw * 9 / 16);
}

pre.block {
    display: block;
    margin-bottom: 20px;
    border-bottom: 1px solid grey;
}
</style>