import ng from "./ng";
import storage from "./storage.js";
import json_screen from '@/assets/data/SCREEN.json';

var db_screen_id = {
};

json_screen.forEach(item => {
    db_screen_id[item.path] = 'R_' + item.code;
});

const packageInfo = require('../../../package.json');

var api = null;

export default {
    install(app) {
        api = app.config.globalProperties.$api;
    },
    log: (from, to) => {
        var p_id = storage.route_id() == undefined ? '' : storage.route_id();

        if (from.path != to.path || p_id == '') {
            var screen_id = db_screen_id[to.path];
            var session_key =
                storage.session_key() == null ? "" : storage.session_key();

            var r_id = ng.uuid();
            var suid = storage.suid();

            if (screen_id != undefined) {
                try {
                    var opt = {
                        s_key: session_key,
                        r_id: r_id,
                        p_id: p_id,
                        code: screen_id,
                        path: to.path,
                        query: to.query,
                        from: from.path,
                        from_query: from.query,
                        suid: suid
                    };

                    api.log
                        .save({}, {}, opt)
                        .then(() => {
                            storage.route_id(r_id);
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                } catch (e) {
                    console.error(e);
                }
            }
        }
    },
    log_h: (code, p_id, data) => {
        var session_key =
            storage.session_key() == null ? "" : storage.session_key();

        var suid = storage.suid();

        var opt = {
            s_key: session_key,
            code: code,
            data: data,
            p_id: p_id,
            suid: suid
        };

        api.log.save({}, {}, opt)
            .then(() => {
            })
            .catch((err) => {
                console.error(err);
            });
    },
    log_p: (code, p_id, data) => {
        var session_key =
            storage.session_key() == null ? "" : storage.session_key();

        var suid = storage.suid();

        var opt = {
            s_key: session_key,
            code: code,
            p_id: p_id,
            suid: suid
        };

        if (data != undefined && data != null) opt.data = data;

        api.log.save({}, {}, opt)
            .then(() => {
            })
            .catch((err) => {
                console.error(err);
            });
    },
    log_s: (code, current) => {
        var session_key =
            storage.session_key() == null ? "" : storage.session_key();

        var screen_id = db_screen_id[current.path];

        var r_id = '';
        var suid = '';
        if (code == 'S_HIDE') {
            r_id = storage.route_id() == undefined ? '' : storage.route_id();
            storage.setting_key_remove('route_id');
            suid = storage.suid();
        } else if (code == 'S_SHOW') {
            // 백그라운드에서 포그라운드로 넘어올때
            if (storage.route_id() == undefined) {
                r_id = ng.uuid();
                storage.route_id(r_id);
            }
            suid = ng.uuid();
            storage.suid(suid);
        }

        var opt = {
            s_key: session_key,
            code: code,
            r_id: r_id,
            path: current.path,
            query: current.query,
            screen_id: screen_id,
            version: packageInfo.version,
            suid: suid
        };

        const blob = new Blob([JSON.stringify(opt)], { type: 'application/json; charset=UTF-8' });

        navigator.sendBeacon('/api/log/', blob);

        /*

        api.log
            .save({}, {}, opt)
            .then(() => {
            })
            .catch((err) => {
                console.error(err);
            });*/
    }

};
